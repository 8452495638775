import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedUrl = 'https://restfulcountries.com/api';
    if (req.url.includes(excludedUrl)) {
      return next.handle(req);
    }

    const authToken = this.authService.getAuthToken();
    let authReq = req;

    if (authToken) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.isRefreshing) {
          this.isRefreshing = true;
          return this.authService.refreshAuthToken().pipe(
            switchMap((newToken: string | null) => {
              this.isRefreshing = false;
              if (newToken) {
                const updatedReq = req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${newToken}`)
                });
                return next.handle(updatedReq);
              }
              return throwError(() => new Error('Failed to refresh token'));
            }),
            catchError((err) => {
              this.isRefreshing = false;
              this.authService.clearTokens();
              return throwError(() => err);
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}